


























import {Component, Prop, Vue} from 'vue-property-decorator';
import dayjs from 'dayjs';
import {HfCalendar} from '@/model/site/components.model';
import {FOCUS_DATE} from '@/store/context.store';
import {Getter} from 'vuex-class';
// FIXME [lint] lint can not find file 'node_modules/buefy/src/components/datepicker/Datepicker.vue.js'
import BDatepicker from 'buefy/src/components/datepicker/Datepicker.vue';

@Component({
  components: {BDatepicker}
})
export default class CalendarComponent extends Vue {

  @Prop(Object) calendar!: HfCalendar;
  @Prop(Array) events?: Date[];
  @Prop(Array) selectableDates?: Date[];
  @Prop(Boolean) inline!: boolean;

  @Getter(FOCUS_DATE) focusDate!: Date;

  set calendarDate(date: Date) {
    // nop
  }

  get calendarDate(): Date {
    return this.focusDate;
  }

  get dayNames(): string[] {
    return ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  }

  get monthNames(): string[] {
    return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  }

  dateFormatter(date: Date): string {
    return `${dayjs(date).format('D. MMMM YYYY')}`;
  }

}
